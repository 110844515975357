import { Button, DateInput, Input } from '@nextui-org/react'
import { color, transform } from 'framer-motion';
import React, { useEffect, useState } from 'react'
import { IoLocationSharp } from "react-icons/io5";
import { HiOutlineMail } from "react-icons/hi";
import {now, parseAbsoluteToLocal} from "@internationalized/date";
import {useDateFormatter} from "@react-aria/i18n";
import {parseZonedDateTime} from "@internationalized/date";
import scp from '../assets/scp.jpg'
import { Link, useLocation } from 'react-router-dom';
import {Tabs, Tab, Card, CardBody, CardHeader} from "@nextui-org/react";
import { FaCar } from "react-icons/fa";
import {Select, SelectItem} from "@nextui-org/react";
import {DateRangePicker} from "@nextui-org/react";
import {DatePicker} from "@nextui-org/react";
import { useRef } from 'react'
import emailjs from '@emailjs/browser';

import RCG from '../assets/RentaCar.png'
import RCS from '../assets/RentaCarSilver.png'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { EffectFlip, Autoplay, Navigation } from 'swiper/modules';

import {getLocalTimeZone} from "@internationalized/date";

import ReactWhatsapp from 'react-whatsapp';
import { IoLogoWhatsapp } from 'react-icons/io';

const SaleCarScreen = () => {
    const [pDate, setPDate] = useState(now(getLocalTimeZone()));
    const [rDate, setRDate] = useState(now(getLocalTimeZone()));

    const [email, setEmail] = useState('')
    const [number, setNumber] = useState('')
    const [vehicleType, setVehicleType] = useState('')
    const [pLocation, setPLocation] = useState('')
    const [driver, setDriver] = useState('')

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();

    // Create a new object that contains dynamic template params
    const templateParams = {
      from_name: number,
      from_email: email,
      from_driver: JSON.stringify(driver.currentKey),
      from_vehicleType: JSON.stringify(vehicleType.currentKey),
      from_pLocation: JSON.stringify(pLocation.currentKey),
      from_pDate: JSON.stringify(pDate.year + "/" + pDate.month + "/" +  pDate.day + " " + pDate.hour+ ":" + pDate.minute+""+ pDate.timeZone),
      from_rDate: JSON.stringify(rDate.year + "/" + rDate.month + "/" +  rDate.day + " " + rDate.hour+ ":" + rDate.minute+""+ rDate.timeZone),
      to_name: 'SAFNY & BROTHERS (PVT) Ltd',
    };

    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = 'service_xyfno1i';
    const templateId = 'template_4c3sa92';
    const publicKey = 'GziQZGOytK6C8SlEM';
  

      emailjs.send(serviceId, templateId, templateParams, publicKey)
        .then(
          () => {
            setNumber('');
            setEmail('');
            setPDate(now(getLocalTimeZone()));
            setVehicleType('')
            setPLocation('');
            setRDate(now(getLocalTimeZone()));
            setDriver('');
          },
          (error) => {
            alert('FAILED...', error.text);
          },
        );
    };

    const location = useLocation()

    useEffect(() => {
      window.scroll(0,0);
    }, [location]);

  return (
    <div className='dark h-fit min-h-fit w-full flex flex-col gap-8 justify-center'>
        <section className='h-[500px] lg:h-[300px] w-full relative flex items-center overflow-hidden'>
            <img
            className='h-[500px] lg:h-[300px] min-h-screen absolute top-0 object-cover w-full z-0 opacity-50 object-center'
                src={scp}
            />
            <div className='h-[500px] lg:h-[300px] min-h-screen w-full max-w-[1024px] mx-auto px-8 flex flex-col  py-4 items-center justify-center relative z-30 gap-2 overflow-hidden'>
                <p className='text-white text-center'>
                Whether you're looking to buy your next car or sell your current one, our platform offers a seamless and user-friendly experience tailored to meet all your automotive trading needs.
                </p>
                <p className='text-white text-center'>The premier destination for trading vehicles with ease and confidence.</p>
                
            </div>
            <div className='h-fit w-full  absolute bottom-0 right-0 z-40 flex justify-end pb-2'>
              <div className='h-fit w-full max-w-[1024px] px-8 bottom-0 right-0 z-40 flex justify-end mx-auto'>
                <ReactWhatsapp number="+94777926383" message="Hello World!!!" className="" >
                    <div className='relative -z-20'>
                        <Button className='border-[2px] border-white/50' variant='bordered'>
                            <IoLogoWhatsapp className='text-white text-2xl' />
                            <p className='text-white capitalize'>Whatsapp</p>
                        </Button>
                    </div>
                </ReactWhatsapp>
              </div>
            </div>
            
        </section>

        <section className='h-fit w-full'>
          <div className='h-fit w-full max-w-[1024px] mx-auto px-8 py-8'>
            <div className='flex flex-col gap-1'>
              <p className='text-2xl text-white font-bold'>Services for Buyers:
              </p>
            </div>
            <div className='flex flex-col md:flex-row py-4 gap-4 flex-wrap mt-4 justify-between'>
              <button className='md:min-h-full w-full py-4 px-4 border-[1px] gap-2 max-w-[450px] hover:border-white/75 duration-200 border-white/25 mx-auto rounded-[12px] flex flex-col justify-center items-center'>
                <p className='text-base font-semibold text-white'>Wide Selection</p>
                <ul className='flex flex-col gap-4 mt-2'>
                    <li className='text-white/50 text-sm text-center'>Explore a diverse inventory of vehicles from trusted sellers. From sedans, SUVs, Jeeps and vans to lorry and trucks, we have something for everyone.</li>
                </ul>
              </button>
              <button className='md:min-h-full w-full py-4 px-4 border-[1px] gap-2 max-w-[450px] hover:border-white/75 duration-200 border-white/25 mx-auto rounded-[12px] flex flex-col justify-center items-center'>
                <p className='text-base font-semibold text-white'>Secure Transactions</p>
                <ul className='flex flex-col gap-4 mt-2'>
                    <li className='text-white/50 text-sm text-center'>Our secure payment system ensures that your purchase is safe and hassle-free.</li>
                </ul>
              </button>
              
            </div>
          </div>
        </section> 
        <section className='h-fit w-full'>
          <div className='h-fit w-full max-w-[1024px] mx-auto px-8 py-8'>
            <div className='flex flex-col gap-1'>
              <p className='text-2xl text-white font-bold'>Services for Sellers
              </p>
            </div>
            <div className='flex flex-col md:flex-row py-4 gap-4 flex-wrap mt-4 justify-between'>
              <button className='md:min-h-full w-full py-4 px-4 border-[1px] gap-2 max-w-[450px] hover:border-white/75 duration-200 border-white/25 mx-auto rounded-[12px] flex flex-col justify-center items-center'>
                <p className='text-base font-semibold text-white'>Reach a Wider Audience</p>
                <ul className='flex flex-col gap-4 mt-2'>
                    <li className='text-white/50 text-sm text-center'>Our platform attracts thousands of car enthusiasts and buyers every day, increasing your chances of a quick and profitable sale.</li>
                </ul>
              </button>
              <button className='md:min-h-full w-full py-4 px-4 border-[1px] gap-2 max-w-[450px] hover:border-white/75 duration-200 border-white/25 mx-auto rounded-[12px] flex flex-col justify-center items-center'>
                <p className='text-base font-semibold text-white'>Support and Guidance</p>
                <ul className='flex flex-col gap-4 mt-2'>
                    <li className='text-white/50 text-sm text-center'>From setting the right price to closing the deal, our team is here to assist you at every step of the selling process.</li>
                </ul>
              </button>
              
            </div>
          </div>
        </section> 
    </div>
  )
}

export default SaleCarScreen